import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.resizeNavbar();
  }

  resizeNavbar() {
    if (this.isWindowAtTheTop()) {
      this.element.classList.remove('Navbar--thinner');
    } else {
      this.element.classList.add('Navbar--thinner');
    }
  }

  isWindowAtTheTop() {
    return window.scrollY < 16;
  }
}
