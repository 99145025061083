import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'box', 'icon'];

  toggle() {
    this.boxTarget.classList.toggle('hidden');

    if (this.iconTarget.innerText == 'add') {
      this.iconTarget.innerText = 'remove'
    } else {
      this.iconTarget.innerText = 'add'
    }
  }
}
