import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'individualTab', 'collectiveTab', 'savingTab', 'individualTable', 'collectiveTable', 'savingTable' ]

  showIndividual(event) {
    if (event.currentTarget.classList.contains('Tab--inactive')) {
      event.currentTarget.classList.remove('Tab--inactive');
      event.currentTarget.classList.add('Tab--active');
      this.collectiveTabTarget.classList.remove('Tab--active');
      this.collectiveTabTarget.classList.add('Tab--inactive');
      this.savingTabTarget.classList.remove('Tab--active');
      this.savingTabTarget.classList.add('Tab--inactive');
      this.individualTableTarget.classList.remove('hidden');
      this.collectiveTableTarget.classList.add('hidden');
      this.savingTableTarget.classList.add('hidden');
    };
  }

  showCollective(event) {
    if (event.currentTarget.classList.contains('Tab--inactive')) {
      event.currentTarget.classList.remove('Tab--inactive');
      event.currentTarget.classList.add('Tab--active');
      this.savingTabTarget.classList.remove('Tab--active');
      this.savingTabTarget.classList.add('Tab--inactive');
      this.individualTabTarget.classList.remove('Tab--active');
      this.individualTabTarget.classList.add('Tab--inactive');
      this.individualTableTarget.classList.add('hidden');
      this.collectiveTableTarget.classList.remove('hidden');
      this.savingTableTarget.classList.add('hidden');
    };
  }

  showSaving(event) {
    if (event.currentTarget.classList.contains('Tab--inactive')) {
      event.currentTarget.classList.remove('Tab--inactive');
      event.currentTarget.classList.add('Tab--active');
      this.collectiveTabTarget.classList.remove('Tab--active');
      this.collectiveTabTarget.classList.add('Tab--inactive');
      this.individualTabTarget.classList.remove('Tab--active');
      this.individualTabTarget.classList.add('Tab--inactive');
      this.savingTableTarget.classList.remove('hidden');
      this.individualTableTarget.classList.add('hidden');
      this.collectiveTableTarget.classList.add('hidden');
    };
  }
}
