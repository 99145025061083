import { Controller } from 'stimulus';
import SmoothScroll from 'smooth-scroll'

export default class extends Controller {

  connect() {
    new SmoothScroll('a[href*="#"]', {
      speed: 200,
    });
  }
}
