import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'categoriesDropdown', 'categoriesIcon', 'accountDropdown', 'accountIcon' ];

  toggleCategories() {
    this.categoriesIconTarget.classList.toggle('rotate-90');
    this.categoriesDropdownTarget.classList.toggle('OfferMenu-dropdown--hidden');
  }

  toggleOffers(event) {
    event.currentTarget.classList.toggle("OfferMenu-item--open")
    event.currentTarget.getElementsByClassName('OfferMenu-submenu')[0].classList.toggle("hidden");
  }

  toggleAccount() {
    this.accountIconTarget.classList.toggle('rotate-90');
    this.accountDropdownTarget.classList.toggle('hidden');
  }

}
