import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'navbar', 'body', 'links', 'button', 'linkToggle' ];

  togglePanel() {
    this.navbarTarget.classList.toggle("h-screen");
    this.navbarTarget.classList.toggle("bg-almost-transparent");
    this.navbarTarget.classList.toggle("bg-gray-900");
    this.navbarTarget.classList.toggle("overflow-y-scroll");
    this.bodyTarget.classList.toggle("overflow-hidden");
    this.linksTarget.classList.toggle("hidden");

    if (this.buttonTarget.innerHTML === 'Menu') {
      this.buttonTarget.innerHTML = 'Fermer';
    } else {
      this.buttonTarget.innerHTML = 'Menu';
    }
  }

  closePanelOnLargeBreakpoint() {
    if (window.innerWidth > 1024 && this.buttonTarget.innerHTML == 'Fermer') {
      this.togglePanel();
      this.closeMenus();
    }
  }

  toggleMenu(event) {
    if (window.innerWidth > 1024) return;
    event.preventDefault();
    if (event.currentTarget.parentElement.classList.contains('Navbar-link--open')) {
      event.currentTarget.parentElement.classList.remove('Navbar-link--open');
    } else {
      this.closeMenus();
      event.currentTarget.parentElement.classList.toggle('Navbar-link--open');
    }
  }

  closeMenus() {
    this.linkToggleTargets.forEach(link => link.classList.remove('Navbar-link--open'));
  }
}
